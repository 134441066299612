import { Component } from '@/types/component';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import { useAppState } from 'lib/hooks/useAppState';
import { cn } from '@/utils/cn';
import localFonts from 'next/font/local';

// Licensed fonts, please do not change
const fontPrimary = localFonts({
  display: 'swap',
  preload: true,
  src: [
    { path: '../../../public/fonts/financierDisplay-normal-400.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/financierDisplay-italic-400.woff2', weight: '400', style: 'italic' },
  ],
  variable: '--font-primary',
});

const fontSecondary = localFonts({
  display: 'swap',
  preload: true,
  src: [
    { path: '../../../public/fonts/FuturaBook_normal_normal_subset1.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/FuturaHeavy_normal_normal_subset1.woff2', weight: '900', style: 'normal' },
  ],
  variable: '--font-secondary',
});

const fontTertiary = localFonts({
  display: 'swap',
  preload: false,
  src: [
    { path: '../../../public/fonts/MinionCaptionRegular_normal_normal_subset1.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/MinionItalicCaption_italic_normal_subset1.woff2', weight: '400', style: 'italic' },
  ],
  variable: '--font-tertiary',
});

const fonts = cn(fontPrimary.variable, fontSecondary.variable, fontTertiary.variable);

export const FeminaStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  const [{ type }] = useAppState();
  const bgClassName = type === 'front' && 'bg-linen-100';

  return <StandaloneEdge className={cn(fonts, bgClassName)}>{children}</StandaloneEdge>;
};
