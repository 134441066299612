/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-black hover:bg-gray-800 focus:bg-gray-800 active:bg-error-600 text-white",
  "colors_secondary": "bg-linen-300 hover:bg-linen-400 focus:bg-linen-400 active:bg-linen-400 active:shadow-[inset_0px_0px_2px] active:shadow-black/25",
  "size_small": "px-5 py-1 text-content-sm gap-1.5",
  "size_medium": "px-6 py-2 text-content-sm gap-1.5",
  "size_large": "px-6 py-2.75 text-content-sm gap-1.5",
  "variant_default": "inline-flex items-center justify-center rounded-sm outline-none focus:outline-none transition-all duration-200 ease-in-out"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;