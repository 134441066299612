import HamburgerIcon from '@/femina/public/image/hamburger-icon.svg';
import { cn } from '@/utils/cn';
import { StandaloneHamburger } from 'base/components/Hamburger';
import { useComponentsState } from 'lib/hooks/useComponentsState';

export const FeminaStandaloneHamburger: typeof StandaloneHamburger = (props) => {
  const {
    Navigation: {
      $drawer: { isVisible },
    },
  } = useComponentsState();

  const className = cn(
    '[&>path]:transition-all',
    '[&>path]:duration-300',
    '[&>path]:origin-center',
    isVisible && [
      'first:[&>path]:opacity-0',
      'last:[&>path]:opacity-0',
      'second:[&>path]:rotate-135',
      'third:[&>path]:-rotate-135',
    ],
  );

  return <StandaloneHamburger content={<HamburgerIcon {...{ className }} />} {...props} />;
};
