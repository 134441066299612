import { tw } from '@/utils/tw';
import BaseHamburgerTheme from 'base/components/Hamburger/theme';

const Hamburger = tw.theme({
  extend: BaseHamburgerTheme,
  slots: {
    base: `[&_svg]:h-5.25`,
  },
});

export default Hamburger;
