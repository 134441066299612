import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    label: `text-gray-800 group-focus-within:text-black group-data-[status=error]:text-error-500`,
    input: `outline-linen-300 text-content-sm bg-white outline outline-0 outline-offset-0 ring-1 ring-inset ring-gray-400 placeholder:text-gray-700 group-data-[status=error]:outline-error-200 group-data-[status=error]:ring-2 group-data-[status=error]:ring-inset group-data-[status=error]:ring-error-500 hover:ring-black hover:placeholder:text-black hover:not-focus:outline-0 focus:outline-4 focus:ring-black focus:placeholder:text-black`,
    caption: `text-content-xs text-gray-800 group-data-[status=error]:text-error-500`,
  },
  variants: {
    variant: {
      primary: {
        label: `text-content-xs`,
      },
      secondary: {
        label: `text-content-sm group-focus-within:text-content-xs group-data-[filled]:text-content-xs`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
