/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { FeminaStandaloneNavigation as ResolvedStandaloneNavigation } from 'sites/femina/components/standalone/Navigation';

export const Navigation: typeof ResolvedStandaloneNavigation = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneNavigation {...props} />
        </ErrorBoundary>
    );
});

export type NavigationProps = PropsFromComponent<typeof Navigation>;

/** @deprecated Use slot architecture instead */
export const NavigationWith = (extras: DynamicStandaloneExtras): typeof Navigation => {
    return function Navigation(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Navigation');
        return <ResolvedStandaloneNavigation {...mergeProps({ options: { theme } }, props)} />;
    }
}