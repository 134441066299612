import { Icon } from '@/components/Icon';
import FeminaLogo from '@/femina/public/image/femina-logo.svg';
import SearchIcon from '@/femina/public/image/femina-search-icon.svg';
import useUserAuth, { UserAuthProps } from '@/hooks/useUserAuth';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneNavigation } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationBarMenu } from 'base/components/Navigation/utils/getNavigationBarMenu';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import { getNavigationSoMeMenu } from 'base/components/Navigation/utils/getNavigationSoMeMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useComponentsState } from 'lib/hooks/useComponentsState';

const logo = <FeminaLogo />;

const menu = getNavigationBarMenu({
  links: [
    { href: '/mode', content: 'Mode' },
    { href: '/skonhet', content: 'Skönhet' },
    { href: '/kultur', content: 'Kultur' },
    { href: '/privatekonomi', content: 'Privatekonomi' },
    { href: '/intervju', content: 'Intervjuer' },
    { href: '/video', content: 'Video' },
    { href: '/feminas-bloggar', content: 'Bloggar' },
  ],
});

type GetButtonsProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logInUrl'>;
const getButtons = ({ isUserLoggedIn, logInUrl }: GetButtonsProps) => (
  <>
    <Navigation.Button
      key="prenumerera"
      link={{
        href: 'https://www.prenumerera.se/tidningar/femina?utm_source=femina.se&utm_medium=menu&utm_campaign=prenumerera2021',
        target: '_blank',
      }}
      options={{ colors: 'secondary', size: 'small' }}
      content="Prenumerera"
    />
    <Navigation.Button
      key="login"
      link={{
        href: isUserLoggedIn ? '/mina-sidor' : logInUrl,
        target: isUserLoggedIn ? '_self' : '_blank',
      }}
      options={{ size: 'small', rel: 'nofollow' }}
      content={isUserLoggedIn ? 'Min sida' : 'Logga in'}
    />
  </>
);

const cookies = (
  <Navigation.Button
    key="cookies"
    options={{ colors: 'none', size: 'none', onClick: showOneTrustConsent }}
    content={<Icon name="cookie" options={{ size: 'medium' }} />}
  />
);

type GetDrawerProps = Pick<UserAuthProps, 'isUserLoggedIn' | 'logInUrl' | 'logOutUrl' | 'myPageUrl'>;

const getDrawer = ({ isUserLoggedIn, logInUrl, logOutUrl, myPageUrl }: GetDrawerProps) =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          { href: '/mode', content: 'Mode' },
          { href: '/skonhet', content: 'Skönhet' },
          [
            { href: '/kultur', content: 'Kultur' },
            { href: '/etikett/litteratur', content: 'Litteratur' },
            { href: '/etikett/film%20och%20tv', content: 'Film & TV' },
            { href: '/etikett/teater', content: 'Teater' },
            { href: '/etikett/musik%20%26%20podd', content: 'Musik & podd' },
            { href: '/i-rampljuset', content: 'I rampljuset' },
            { href: '/nostalgi', content: 'Nostalgi' },
          ],
        ],
      },
      {
        links: [
          { href: '/kronika', content: 'Krönikor' },
          [
            { href: '/livsstil', content: 'Livsstil' },
            { href: '/inredning', content: 'Inredning' },
            { href: '/mat-och-dryck', content: 'Mat & dryck' },
            { href: '/resor', content: 'Resor' },
          ],
          { href: '/intervju', content: 'Intervjuer' },
        ],
      },
      {
        links: [
          { href: '/livsberattelser', content: 'Livsberättelser' },
          { href: '/privatekonomi', content: 'Privatekonomi' },
          [
            { href: '/halsa', content: 'Hälsa' },
            { href: '/etikett/relationer', content: 'Relationer' },
          ],
          { href: '/video', content: 'Video' },
          { href: '/feminas-bloggar', content: 'Bloggar' },
        ],
      },
      {
        links: [
          { href: '/kontakt', content: 'Kontakt' },
          { href: '/om-oss', content: 'Om Femina' },
          { href: '/nyhetsbrev', content: 'Nyhetsbrev' },
          { href: '/om-cookies', content: 'Cookies' },
          {
            href: '',
            content: 'Hantera preferenser',
            options: {
              onClick: (event) => {
                event.preventDefault();
                showOneTrustConsent();
              },
            },
          },
          { href: '/alla-amnen', content: 'Alla ämnen' },
          { href: '/creative-studio', content: 'Creative Studio' },
          isUserLoggedIn
            ? { href: logOutUrl, content: 'Logga ut', options: { rel: 'nofollow' } }
            : { href: logInUrl, content: 'Skapa konto', options: { rel: 'nofollow' } },
          {
            href: myPageUrl,
            content: (
              <div className="flex items-center gap-1">
                Mina Inställningar
                <Icon name="externalLink" />
              </div>
            ),
            options: {
              className: isUserLoggedIn ? '' : 'hidden',
              rel: 'nofollow',
            },
          },
        ],
      },
    ],
    options: {
      $link: {
        variant: 'drawer',
      },
    },
  });

const soMe = getNavigationSoMeMenu({
  links: [
    { href: 'https://facebook.com/femina.se', name: 'facebook', options: { 'aria-label': 'Länk till Facebook' } },
    {
      href: 'https://instagram.com/feminasverige',
      name: 'instagram',
      options: { 'aria-label': 'Länk till Instagram' },
    },
    { href: 'https://x.com/femina_se', name: 'x', options: { 'aria-label': 'Länk till X' } },
    { href: 'https://pinterest.se/feminasverige', name: 'pinterest', options: { 'aria-label': 'Länk till Pinterest' } },
    {
      href: 'https://news.google.com/publications/CAAqBwgKMM3UpAswkN-8Aw?hl=sv&gl=SE&ceid=SE%3Asv',
      options: {
        colors: 'border',
        size: 'alternative',
        'aria-label': 'Länk till Google nyheter',
      },
      content: (
        <>
          <GoogleNewsIcon className="w-5" />
          Google nyheter
        </>
      ),
    },
  ],
});

export const FeminaStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isUserLoggedIn, logInUrl, logOutUrl, myPageUrl } = useUserAuth();
  const {
    Navigation: {
      $drawer: { show: openDrawer },
    },
  } = useComponentsState();

  const search = (
    <Navigation.Button
      key="search"
      options={{ colors: 'none', size: 'none', className: 'hidden sm:block', onClick: openDrawer }}
      content={<SearchIcon className="h-6 w-6" />}
    />
  );

  const controls = [getButtons({ isUserLoggedIn, logInUrl }), search, cookies];

  const drawer = getDrawer({ isUserLoggedIn, logInUrl, logOutUrl, myPageUrl });

  return (
    <StandaloneNavigation
      {...{
        menu,
        controls,
        logo,
        drawer,
        soMe,
      }}
      options={{
        $search: {
          $submit: {
            options: {
              size: 'large',
            },
          },
        },
      }}
    />
  );
};
