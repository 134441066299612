/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "bg-none border-transparent hover:bg-linen-200 focus:border-black focus:bg-linen-100 active:border-none active:bg-linen-300",
  "colors_filled": "bg-linen-400 border-transparent hover:border-none hover:bg-linen-400 focus:border-black focus:bg-linen-300 active:border-none active:bg-linen-400",
  "colors_border": "border-black hover:border-black hover:bg-linen-300 focus:border-black focus:bg-linen-300 active:border-black active:bg-linen-400",
  "size_default": "w-8 h-8",
  "variant_default": "inline-flex items-center justify-center border focus:border-[1px] transition-all duration-200 ease-in-out rounded-full focus:outline-none active:outline-none",
  "size_alternative": "h-8 px-2.5 text-content-sm gap-x-1"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;