import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import FeminaLogo from '@/femina/public/image/femina-logo.svg';
import FaceBlob from '@/femina/public/image/footer-face-blob.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';

const currentYear = new Date().getFullYear();

const logo: StandaloneFooterProps['logo'] = <FeminaLogo />;

const description: StandaloneFooterProps['description'] = (
  <>
    Femina berör på djupet men tar ytan på allvar. Hos oss är en kvinnas alla
    intressen lika viktiga. Vilket ämne vi än skriver om står Femina alltid på kvinnornas sida vilket vi gjort sedan
    starten.
  </>
);

const editors: StandaloneFooterProps['editors'] = 'Chefredaktör och ansvarig utgivare: Åsa Liliegren';

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>
    <Footer.SoMe href="https://www.facebook.com/femina.se/" name="facebook" aria-label="Länk till Facebook" />
    <Footer.SoMe href="https://mobile.x.com/femina_se" name="x" aria-label="Länk till X" />
    <Footer.SoMe href="https://www.instagram.com/feminasverige/" name="instagram" aria-label="Länk till Instagram" />
    <Footer.SoMe href="https://www.pinterest.se/feminasverige/" name="pinterest" aria-label="Länk till Pinterest" />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAqBwgKMM3UpAswkN-8Aw?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        colors: 'secondary',
        className: 'bg-linen-400 !rounded-full py-1.5',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

const columns: StandaloneFooterProps['columns'] = (
  <>
    <Footer.Column>
      <Footer.List.Title>Om Femina</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/om-oss" content="Om oss" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/nyhetsbrev" content="Nyhetsbrev" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/alla-amnen" content="Alla ämnen" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/vara-skribenter" content="Våra skribenter" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/om-cookies" content="Om cookies" />
        </Footer.List.Item>
        <Footer.List.Item>
          <button type="button" onClick={showOneTrustConsent}>
            Hantera preferenser
          </button>
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/integritetspolicy/" target="_blank" content="Integritetspolicy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://www.aller.se/ai-policy/" target="_blank" content="Aller Medias AI-policy" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link
            href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-feminase/10293612"
            content="Kommersiell policy Femina"
          />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Femina.se</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="/mode" content="Mode" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/skonhet" content="Skönhet" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/kultur" content="Kultur" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/privatekonomi" content="Privatekonomi" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/intervju" content="Intervjuer" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/feminas-bloggar" content="Bloggar" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>

    <Footer.Column>
      <Footer.List.Title>Kontakta oss</Footer.List.Title>
      <Footer.List>
        <Footer.List.Item>
          <Link href="https://prenumerera.se/tidningar/femina" content="Bli prenumerant" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="/kontakt" content="Kontakta oss här" />
        </Footer.List.Item>
        <Footer.List.Item>
          <Link href="https://femina.ocast.com" content="Annonsera" />
        </Footer.List.Item>
      </Footer.List>
    </Footer.Column>
  </>
);

const copyright: StandaloneFooterProps['copyright'] = (
  <>
    <p>
      För prenumerationsärenden, ring{' '}
      <a className="hover:underline" href="tel:0424443013">
        042 444 30 00
      </a>{' '}
      • Ansvarig utgivare Åsa Liliegren © Copyright {currentYear}{' '}
      <a className="hover:underline" href="https://www.femina.se">
        femina.se
      </a>
    </p>
    <p>
      Femina är en del av{' '}
      <a className="hover:underline" target="_blank" href="https://www.aller.se">
        Aller media
      </a>
      . Humlegårdsgatan 6, 114 46 Stockholm.{' '}
      <a className="hover:underline" href="tel:086794600">
        08 679 46 00
      </a>
    </p>
  </>
);

export const FeminaStandaloneFooter: typeof StandaloneFooter = () => {
  return (
    <div className="relative z-1 mx-auto w-full max-w-screen-xl">
      <FaceBlob className="absolute left-0 top-0 z-1 hidden h-full lg:block" />
      <StandaloneFooter {...{ logo, description, editors, soMe, columns, copyright }} />
    </div>
  );
};
